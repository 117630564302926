import React from "react"
import Layout from "../components/App/Layout"
import SEO from "../components/App/SEO"
import NavbarStyleTwo from "../components/App/NavbarStyleTwo"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"
import { GetStartedWithUs } from "../components/Common/GetStartedWithUs"
import { Link } from "gatsby"
import { SEO_TITLES } from "../config/seo"
import { PricingColumns } from "../components/PricingColumns/PricingColumns"

import { PLANS } from "../config/plans"
import { EMOJIS } from "../config/emojis"

import { CommonHeaderAndFooter } from "../layout/CommonHeaderAndFooter"

const PRICING_PAGE_VARIANT = {
  ONE: {
    banner: {
      heading: "Pricing",
      subheading: `Your company's win in exceptional CX just a single click away`
    },
    pricingSection: {
      heading: `Find the perfect plan for your company`
    }
  }
}

const Pricing = ({ variant }) => {
  variant = PRICING_PAGE_VARIANT.ONE
  return (
    <CommonHeaderAndFooter seoTitle={SEO_TITLES.VARIANT3.PRICING}>
      <PageBanner
        pageTitle={variant.banner.heading}
        variant={variant}
        showShapes={false}
        compact
      />
      <div className="pr-5 pl-5 bg-color-grey">
        <h2 className="d-flex justify-content-center pt-5 pb-5 bg-color-grey mb-0">
          {variant.pricingSection.heading} {EMOJIS.ROCKET({ className: "ml-2 d-none d-lg-block", size: 40 })}
        </h2>
      </div>
      <div className="pricing-area bg-f9f9f9 pt-0 pb-70">
        <div className="container">
          <PricingColumns
            pricingPlans={PLANS}
          />
        </div>
      </div>

      <GetStartedWithUs />
    </CommonHeaderAndFooter>
  )
}

export default Pricing