import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export const SmallNotice = ({
                              centerHorizontally = true,
                              centerVertically = false,
  children,

}) => (
  <div
    className={
      cx('small-notice', {
        'center-h': centerHorizontally,
        'center-v': centerVertically,
      })
    }
  >{children}</div>
);

SmallNotice.propTypes = {
  children: PropTypes.node.isRequired,
  centerHorizontally: PropTypes.bool,
  centerVertically: PropTypes.bool,
}