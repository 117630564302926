import { PRICING_COLUMN_TEMPLATES } from "../components/PricingColumns/constants"
import { PLAN_NAMES } from "../config/plans"

export class IdentityUtils {

  static addPricingColumnTemplateIdentities = (template) => ({
    isZenTemplate: template === PRICING_COLUMN_TEMPLATES.ZEN,
    isDefaultTemplate: template === PRICING_COLUMN_TEMPLATES.DEFAULT,
    isCohTemplate: template === PRICING_COLUMN_TEMPLATES.COH,
  });

  static addPricingPlanIdentities = (plan) => ({
    isFree: plan.type === PLAN_NAMES.FREE,
    isStarter: plan.type === PLAN_NAMES.STARTER,
    isGrow: plan.type === PLAN_NAMES.GROW,
    isEnterprise: plan.type === PLAN_NAMES.ENTERPRISE,
  })

}