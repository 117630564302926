export class ArrayUtils {

  arr = [];

  constructor(arr) {
    this.array = arr;
  }

  static getAllPrevItemsByIndexStatic(targetIndex) {
    this.array = [...this.array].filter((_, index) => index < targetIndex);
  }

  getAllPrevItemsByIndex(targetIndex) {
    this.array = [...this.array].filter((_, index) => index < targetIndex);
    return this;
  }

  get() {
    return this.array;
  }

}
