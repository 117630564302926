import React from 'react';
import PropTypes from 'prop-types';
import { PricingColumn } from "./comps/PricingColumn"
import { DataUtils } from "../../utils/dataUtils"
import { ArrayUtils } from "../../utils/arrayUtils"
import { toPairs, flatten } from 'lodash';

import { HIGHLIGHTED_PLAN } from "../../config/plans"
import { ObjectUtils } from "../../utils/objectUtils"
import { FEATURES_DISPLAY_TYPE, PRICING_COLUMN_TEMPLATES } from "./constants"

import { IdentityUtils } from "../../utils/identityUtils"

export const PricingColumns = ({ pricingPlans, displayType = FEATURES_DISPLAY_TYPE.COMBINED}) => {

  const isCombinedDisplayType = displayType === FEATURES_DISPLAY_TYPE.PREV_PLAN_PLUS_CURRENT;
  const isPrevPlanPlusCurrentDisplayType = displayType === FEATURES_DISPLAY_TYPE.PREV_PLAN_PLUS_CURRENT;
  const plansList = ObjectUtils.mapToArrayWithKey(pricingPlans, 'type');

  const resolvePlansList = () => {
    if (isCombinedDisplayType) {
      return plansList.map((currentPlan, currIndex) => {

        const allPrevPlans = (new ArrayUtils(plansList)).getAllPrevItemsByIndex(currIndex).get();
        const allPrevPlansFeatures = flatten(allPrevPlans.map((plan) => plan.features));
        return {
          ...currentPlan,
          features: allPrevPlansFeatures.concat(currentPlan.features)
        }
      })
    }

    return plansList;
  }

  const resolvedList = resolvePlansList();

  return (
    <div className="row">
      {resolvedList.map((plan, index) => {
          return (
            <PricingColumn
              displayType={displayType}
              key={plan.name}
              planCount={Object.keys(pricingPlans).length}
              plansConfig={pricingPlans}
              plan={{ ...plan, ...IdentityUtils.addPricingPlanIdentities(plan) }}
              template={PRICING_COLUMN_TEMPLATES.ZEN}
            />
          );
      })}
    </div>
  );
}