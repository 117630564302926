import React from 'react';
import PropTypes from "prop-types"
import cx from 'classnames';
import _ from 'lodash';
import { PLAN_FEATURES, PLAN_NAMES } from "../../../config/plans"
import { WithTooltip } from "../../WithTooltip"
import { Button } from "../../Button/Button"
import { COMPANY_DETAILS } from "../../../config"
import { GetStartedButton } from "../../GetStartedButton"
import { SmallNotice } from "../../SmallNotice/SmallNotice"

export const PriceBlock = ({ plan, template }) => {
  const renderPriceValue = (plan) => {
    if (!plan.pricePerSeatPerMonth) {
      return "Free"
    }
    if (plan.pricePerSeatPerMonth === "CUSTOM") {
      return "Custom"
    }
    return `$${plan.pricePerSeatPerMonth}`
  }

  const isEnterprisePlan = plan.type === PLAN_NAMES.ENTERPRISE
  const isFreePlan = plan.type === PLAN_NAMES.FREE

  const renderCtaButton = () => {
    const Wrapper = ({ children }) => <div className="pt-2">{children}</div>
    if (isEnterprisePlan) {
      return (
        <Wrapper>
          <WithTooltip
            tooltipId={"contact-sales-via-email"}
            tooltipContent={
              <span>Contact us via email or live-chat and we'll get back to you ASAP!</span>
            }
          >
            <div>
              <Button
                isLink
                mailToLink={isEnterprisePlan}
                openInNewTab
                link={`mailto:${COMPANY_DETAILS.EMAILS.SALES}`}
              >
                {"Contact sales"}
              </Button>
            </div>
          </WithTooltip>
        </Wrapper>
      )
    }
    return (
      <Wrapper>
        <GetStartedButton />
      </Wrapper>
    )
  }

  return (
    <div className="price">
      {renderPriceValue(plan)}
      <span style={isEnterprisePlan || isFreePlan ? { visibility: "hidden" } : null}>per user / month</span>
      {/*<span>per user / month</span>*/}
      {plan.minSeats && (
        <SmallNotice>
          Min {plan.minSeats} seats.
        </SmallNotice>
      )}
      {plan?.restrictions?.maxSessionsPerDay && (
        <SmallNotice>
          {PLAN_FEATURES.VIEW_AND_CONTROL_LIMITED_SESSIONS.label(plan?.restrictions?.maxSessionsPerDay)}
        </SmallNotice>
      )}
      {renderCtaButton()}
      {/*{false && plan.minSeats && <div className="small-notice tac center-h">Min {plan.minSeats} seats.</div>}*/}
    </div>
  )
}

PriceBlock.propTypes = {
  plan: PropTypes.object.isRequired,
  planCount: PropTypes.number.isRequired,
  template: PropTypes.string.isRequired
}