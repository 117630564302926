import React from 'react';
import PropTypes from "prop-types"
import cx from 'classnames';
import { GridUtils } from "../../../utils/grid"

export const ColumnWrapper = ({ children, planCount, template }) => {
  const grid = {
    lg: 12 / planCount,
    md: 6,
    sm: 12
  }
  return (
    <div className={
      cx(
        GridUtils.threeBreakClasses(grid),
        "d-flex align-items-stretch pricing-column flex-column",
        `template--${template.toLowerCase()}`
      )
    }>
      {children}
    </div>
  )
}
