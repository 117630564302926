import React from "react"
import PropTypes from "prop-types"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import BaseTooltip from "react-bootstrap/Tooltip"
import { FONT_AWESOME_ICONS } from "../../../config/icons"

import { WithTooltip } from "../../WithTooltip"

const Tooltip = ({ text, id, ...rest }) => (
  <BaseTooltip id={id} {...rest}>
    {text}
  </BaseTooltip>
);

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
}

export const PlanFeatures = ({ plan, isZenTemplate }) => {
  if (isZenTemplate) {
    return (
      <div className="pricing-features">
        <ul>
          {plan.features.map((feat) => (
            <li className="plan-feature-row pt-3" key={feat.label()}>
              <span className="plan-feature-label tal">{feat.label()}</span>
              {feat?.tooltip?.text && (
                <WithTooltip
                  tooltipId={'feature-' + feat.label()}
                  tooltipContent={feat.tooltip.text}
                >
                  <p className="feature-icon-container ml-3">
                    {FONT_AWESOME_ICONS.INFO_CIRCLE(1)}
                  </p>
                </WithTooltip>
              )}
            </li>
          ))}
        </ul>
      </div>
    )
  }
  return (
    <div className="pricing-features">
      <ul>
        {plan.features.map((feat) => (
          <li key={feat.label()}>{feat.label()}</li>
        ))}
      </ul>
    </div>
  )
}