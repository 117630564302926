import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import cx from "classnames"
import { GridUtils } from "../../../utils/grid"
import { PRICING_COLUMN_TEMPLATES, FEATURES_DISPLAY_TYPE } from "../constants"
import { IdentityUtils } from "../../../utils/identityUtils"
import { FLATICON_ICONS } from "../../../config/icons"
// import { Tooltip } from "../../Tooltip/Tooltip"
import { PLAN_NAMES } from "../../../config/plans"
import BaseTooltip from "react-bootstrap/Tooltip"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import { TOOLTIP_TYPES, TOOLTIP_APPEARANCE_MODES } from "../../Tooltip/constants"
import { PLAN_FEATURES } from "../../../config/plans"
import { FONT_AWESOME_ICONS } from "../../../config/icons"
import { SmallNotice } from "../../SmallNotice/SmallNotice"
import { PLATFORM_BASE_URL } from "../../../config/common"
import { COMPANY_DETAILS } from "../../../config"
import { GetStartedButton } from "../../GetStartedButton"
import { WithTooltip } from "../../WithTooltip"

import { PlanFeatures } from "./PlanFeatures"
import { HIGHLIGHTED_PLAN } from "../../../config/plans"

import { Button } from "../../Button/Button"
import { ColumnWrapper } from "./ColumnWrapper"
import { PriceBlock } from "./PriceBlock"

import "./PricingColumn.scss"

const CURR_TEMPLATE = PRICING_COLUMN_TEMPLATES.ZEN

export const PricingColumn = ({
                                plansConfig,
                                plan,
                                lowerTierPlan,
                                higherTierPlan,
                                durationType,
                                planCount,
                                template,
                                displayType
                              }) => {
  template = CURR_TEMPLATE
  const commonProps = {
    plan, template, planCount, ...IdentityUtils.addPricingColumnTemplateIdentities(template)
  }

  // console.log('PricingColumn plan', plan)
  if (template === PRICING_COLUMN_TEMPLATES.ZEN) {
    return (
      <ColumnWrapper {...commonProps}>
        <div className="single-pricing-box pl-lg-2 pr-lg-2 advanced-card-hover-effect">
          <h3 className="text-align-center mb-lg-3 mb-sm-2 tac">{plan.name}</h3>
          <p className="plan-subheading d-block tac mb-lg-3 mb-sm-2">{plan.subheading}</p>
          <PriceBlock {...commonProps} />
          <PlanFeatures {...commonProps} />
        </div>

      </ColumnWrapper>
    )
  }

  return (
    <ColumnWrapper {...commonProps}>
      <div className="single-pricing-box">
        <div className="pricing-header">
          <div className="icon">
            <i className="flaticon-paper-plane"></i>
            <div className="circles-box">
              <div className="circle-one"></div>
            </div>
          </div>
          <h3 className="text-align-center">{plan.name}</h3>
        </div>

        <PlanFeatures {...commonProps} />

        <PriceBlock {...commonProps} />

        <Link to="/#" className="default-btn">
          Book Now
        </Link>
      </div>
    </ColumnWrapper>
  )
}

PricingColumn.propTypes = {
  template: PropTypes.string.isRequired,
  displayType: PropTypes.string.isRequired
}