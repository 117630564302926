import React from 'react';
import cx from 'classnames';

const defaultEmojiFontSizeByBreakpoints = {
  lg: 40,
  md: 25,
  sm: 20,
};

export const EMOJIS = {
  ROCKET: (props) => (
    <span className={cx(props?.className)} style={{ fontSize: props?.size || 40 }}>🚀</span>
  ),
}